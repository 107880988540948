import Axios from 'axios'
import MD5 from 'js-md5';
import Store from '@/store'
import { IsPC, ApiUrl } from '@/utils/util'
import router from '@/router';

let auth = '';
//添加请求拦截器        跟这个 是同时进行的

// Axios.interceptors.request.use((config)=>{
//     return config;
// })
// debugger
Axios.interceptors.request.use(config => { //在发送请求之前执行方法
	auth = '';
	if(config.useLog){ //请求如果需要用户登录
		//判断用户是否登录
		auth ='Bearer '+sessionStorage.getItem('token');
		// auth ='Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhc2NvcGUiOiIiLCJleHAiOjE2OTQ2NjAwNTEsImlkZW50aXR5IjoxLCJuaWNlIjoi5p2O6YeR6b6ZIiwib3JpZ19pYXQiOjE2OTQwNTUyNTEsInJvbGVpZCI6MCwicm9sZWtleSI6IiIsInJvbGVuYW1lIjoiIn0.wRYENa2W4f797HZjJPjLYt59Okm2YQHLMMzAd0_Y6UQ';
	}else{
		auth=''
		// auth = 'Zgkc ksk.' + Math.round(new Date().getTime()/1000).toString() + '.' + MD5('ksk.' + Math.round(new Date().getTime()/1000).toString() + '.common_password');
	}
	// config.headers['Content-Type'] = "application/json; charset=utf-8";
	config.headers['Authorization'] = auth;

	config = formatConfig(config);
	return config
}, error => { //请求错误时执行方法
	return Promise.reject(error)
})

//添加响应拦截器
Axios.interceptors.response.use(response => { //响应数据执行方法
	successState(response);
	return response.data
}, error => {
	errorState(error.response)
});
//接口请求成功返回的方法
function successState(res) {
	// if(res.data.msg.includes('token')){
	// 	if(IsPC()){
	// 		Store.commit('ShowLoginModal') //显示登录提示
	// 	}else{
	// 		Store.commit('ShowMLoginModal') //显示登录提示
	// 	}
	// }
	if (res.data.code && (res.data.code == 401)) {
		console.log('未登录')
		Store.commit('ShowLoginModal')
		router.push({
			path:'/'
		})
		// if(IsPC()){
		// 	Store.commit('ShowLoginModal') //显示登录提示
		// }else{
		// 	// Store.commit('ShowMLoginModal') //显示登录提示
		// 	router.push({
		// 		path:'/login'
		// 	})
		// }
	}
	return
}
/*二次封装axios*/
//错误执行方法
function errorState(res) {
	// 如果http状态码正常，则直接返回数据
	if(res && res.status && (res.status == 200 || res.status == 304 || res.status == 400)) {
		return res
		// 如果不需要除了data之外的数据，可以直接 return response.data
	} else if(res && res.status && res.data && res.status == 500) {
		// Vue.prototype.$Message.error(res.data.msg);
	} else if(res && res.statusText) {
		// Vue.prototype.$Message.error(res.statusText);
	}
}

//格式化请求数据
function formatConfig(config) {
	if(['get', 'delete'].includes(config.method) && config.params) { //get请求
		Object.keys(config.params).forEach(k => {
			if(config.params[k] === "") {
				delete config.params[k]
			}
			config.params[k]=HandleSpecial(config.params[k]);
		});
		return config;
	}
	if(config.method == "put" || config.method == "post") { //put请求和delete请求post请求
		if(config.data && config.headers['Content-Type'] == "application/x-www-form-urlencoded") {
			config.data = getParamsData(config.data);
			return config;
		}
		if(config.data) {
			Object.keys(config.data).forEach(k => {
				// if(config.data[k] === "" || config.data[k] === null) {
				// 	delete config.data[k]
				// } else {
					config.data[k] = HandleSpecial(config.data[k]);
				// }
			});
		}
	}
	return config;
}
//删除value是空的项  这个
function getParamsData(_data) {
	//let index = -1;
	let arr = [];
	//let length = 0;
	let res = {};
	let _str = "";
	if(!_data) {
		return res;
	}
	arr = _data.split('&');
	//length = arr.length;
	arr.forEach(t => {
		res[t.split('=')[0]] = t.split('=')[1];
	});
	Object.keys(res).forEach(k => {
		if((typeof(res[k]) == 'string')) {
			res[k] = HandleSpecial(res[k]);
		}
		if(res[k] !== "") {
			_str += ((_str ? "&" : "")) + k + "=" + res[k];
		}
	})
	res = _str;
	return res;
}
//特殊字符串处理,除去
function HandleSpecial(params) {
	if(params && (typeof(params) == 'string')) {
		params = fifterStr(params);
	}
	if(params && Array.isArray(params)) {
		params.forEach(t => {
			if(t) {
				t = HandleSpecial(t);
			}
		});
	}
	if(params && Object.prototype.toString.call(params) === '[Object Object]') {
		Object.keys(params).forEach(k => {
			if(params[k]) {
				params[k] = HandleSpecial(params[k]);
			}
		})
	}
	return params;
}
//去掉多余字符串
function fifterStr(str) {
	str = str.replace(/[\n\r\t]/g, "");
	str = str.replace(/(^\s*)|(\s*$)/g, "");
	return str
}

//设置默认请求头
// Axios.defaults.headers = {
// 	'Content-Type': 'application/x-www-form-urlencoded',
// }
Axios.defaults.baseURL = ApiUrl + "/api";

export default Axios;